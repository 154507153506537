
import { defineComponent } from 'vue';
import { allFields, fieldType } from '../assets/data';
import SideNav from './SideNav.vue';

import $ from 'jquery';
import { useDark } from '@vueuse/core';
import Toast from './Toast.vue';
import { getHash, stripTitle } from './../util/url';

export default defineComponent({
  name: 'List',
  components: {
    SideNav,
    Toast,
  },
  data() {
    return {
      startField: {
        id: 0,
        selected: false,
        selectable: false,
        title: 'message.list.startSlideTitle',
        text: 'message.list.startSlideText',
      } as fieldType,
      endField: {
        id: allFields.length + 1,
        selected: false,
        selectable: false,
        title: 'message.list.endSlideTitle',
        text: 'message.list.endSlideText',
      } as fieldType,
      showToast: false,
      toastMessage: '',
      scrollPosition: 0,
      disableScroll: false,
      showSidebar: false,
      isDark: useDark(),
    };
  },
  methods: {
    stripTitle: stripTitle,
    scrollAndSetHash(targetField: fieldType) {
      if (targetField.id > 0 && targetField.id < this.fields.length - 1) {
        window.location.hash = getHash(
          targetField.id,
          this.$t(targetField.title)
        );
        navigator.clipboard.writeText(location.href);
        this.toast(this.$t('message.board.toast.urlCopied'));
      }
      this.scroll(targetField.id, true);
    },
    scroll(targetFieldId: number, animate = false) {
      // scroll exactly one screen height when scrolling down from first slide.
      // Otherwise scroll to offset of selected field
      let offset =
        targetFieldId == 1 && window.scrollY < window.innerHeight
          ? window.innerHeight
          : $('#field' + targetFieldId).offset()?.top;
      if (animate)
        $('html, body').animate({ scrollTop: offset ? offset : 0 }, 250);
      else $('html, body').scrollTop(offset ? offset : 0);
    },
    hideSidebar() {
      this.showSidebar = false;
      $('body').css('overflow', 'auto');
    },
    toast(text: string) {
      this.toastMessage = text;
      if (!this.showToast) {
        setTimeout(() => (this.showToast = false), 3000);
      }
      this.showToast = true;
    },
  },
  mounted() {
    const selectedStmt = new URL(location.href).hash
      .substring(1)
      .split('?')
      .find((s) => s.match('^stmt=[0-9]+-.+$'))
      ?.split('=')[1]
      .split('-')[0];
    const stmtGiven = selectedStmt && !Number.isNaN(selectedStmt);
    if (stmtGiven)
      setTimeout(() => {
        this.scroll(
          Math.max(Math.min(Number(selectedStmt), this.fields.length - 1), 1)
        );
      }, 750);
  },
  computed: {
    cssVars() {
      return {
        '--list-count': allFields.length + 2,
      };
    },
    fields(): fieldType[] {
      return [this.startField, ...this.getSortedDataFields, this.endField];
    },
    getSortedDataFields(): fieldType[] {
      return allFields.sort((a, b) => a.id - b.id);
    },
  },
});
